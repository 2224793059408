<template>
  <div class="pre-footer" :class="{ dark, 'bg-shadow-hide': bgShadowHide, 'landing-image': background }">
    <div class="container">
      <div class="content" :class="[`offset-${offset}`, columns, mdcolumns, mdoffset ? `offset-${mdoffset}` : '']">
        <h3 class="h2">
          {{ title }}
        </h3>
        <p class="subtitle" :class="[sColumns]">
          {{ description }}
        </p>
      </div>
      <div class="row-btn col-4">
        <button
          v-if="bgShadowHide" class="btn-primary btn-lg get-started-btn"
          :data-event="`${eventPrefix}pre-footer.click`"
          @click="$emit('scroll-to-last-section')"
        >
          {{ buttonTitle }}
        </button>
        <button
          v-else-if="checkout"
          class="btn-primary btn-lg get-started-btn col-4"
          :data-event="`${eventPrefix}pre-footer.click`"
          @click="$emit('subscribe')"
        >
          {{ buttonTitle }}
        </button>
        <nuxt-link
          v-else :to="buttonLink"
          class="btn-primary btn-lg get-started-btn"
          data-event="start_your_career_3.click"
        >
          {{ buttonTitle }}
        </nuxt-link>
      </div>
    </div>
    <div v-if="!background" class="bg">
      <img class="bg-image" :src="image" :alt="title">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: '', required: true },
    description: { type: String, default: '', required: true },
    buttonTitle: { type: String, default: '', required: true },
    buttonLink: { type: String, default: '', required: true },
    columns: { type: String, default: 'col-6' },
    mdcolumns: { type: String, default: '' },
    offset: { type: Number, default: 0 },
    mdoffset: { type: Number, default: 0 },
    background: Boolean,
    dark: Boolean,
    bgShadowHide: Boolean,
    checkout: Boolean,
    sColumns: { type: String, default: '' },
    eventPrefix: { type: String, default: '' },
  },
  computed: {
    image() {
      if (this.$config.platform === 'fa') {
        return require('../assets/images/fa/fa-pre-footer.webp');
      }

      return require(`../assets/images/pre-footer-icons-1920${this.dark ? '-dark' : ''}.webp`);
    },
  },
};
</script>

<style lang="scss" scoped>
.pre-footer {
  background-color: var(--main-light-shade-1);
  color: var(--main-dark-shade-0);
  padding-top: var(--spacing-100);
  text-align: center;

  &.blog-pre-footer {
    padding-top: var(--spacing-120);
  }

  .content h3 {
    margin-left: auto;
    margin-right: auto;
  }

  &.bg-shadow-hide .bg {
    &:before,
    &:after {
      display: none;
    }
  }

  .content {
    margin: 0 auto;

    .subtitle {
      margin: 0 auto var(--spacing-44);
    }
  }

  .row-btn {
    margin: 0 auto;
    max-width: 320px;

    .get-started-btn {
      width: 100%;
    }
  }

  .btn-primary {
    display: block;
  }

  .bg {
    position: relative;
    margin: var(--spacing-100) auto 0;
    overflow: hidden;

    &:before,
    &:after {
      content: '';
      display: block;
      width: 240px;
      height: 100%;
      position: absolute;
      top: 0;
      opacity: 0.8;
    }

    &:before {
      left: 0;
      background: linear-gradient(90deg, var(--main-light-shade-1) 0%, rgba(250, 250, 251, 0.7) 41.67%, rgba(250, 250, 251, 0) 100%);
    }

    &:after {
      right: 0;
      background: linear-gradient(270deg, var(--main-light-shade-1) 0%, rgba(250, 250, 251, 0.7) 41.67%, rgba(250, 250, 251, 0) 100%);
    }

    img {
      width: 100%;
      height: 200px;
      display: block;
      object-fit: cover;
      object-position: center;
    }
  }

  &.dark {
    background-color: var(--main-dark);
    color: var(--main-light);

    .subtitle {
      color: var(--main-light-shade-4);
    }

    .bg {
      &:before {
        background: linear-gradient(90deg, var(--main-dark) 0%, rgba(0, 12, 31, 0.7) 41.67%, rgba(0, 12, 31, 0) 100%);
      }

      &:after {
        background: linear-gradient(270deg, var(--main-dark) 0%, rgba(0, 12, 31, 0.7) 41.67%, rgba(0, 12, 31, 0) 100%);
      }
    }
  }
}

@include bp($bp-laptop) {
  .pre-footer {
    .row-btn {
      max-width: 225px;
    }
  }
}

@include bp($bp-mobile) {
  .pre-footer {
    .bg {
      &:before,
      &:after {
        content: none;
      }

      img {
        height: 150px;
      }
    }

    .row-btn {
      padding: 0;
    }

    &.resources-prefooter {
      .bg {
        &:before,
        &:after {
          content: '';
          display: block;
          width: 148px;
          height: 100%;
          position: absolute;
          top: 0;
        }

        &:before {
          left: 0;
          background: linear-gradient(180deg, var(--main-dark) 0%, rgba(0, 12, 31, 0.7) 41.67%, rgba(0, 12, 31, 0) 100%);
          border-radius: 7px;
          transform: matrix(0, 1, 1, 0, 0, 0);
        }

        &:after {
          right: 0;
          background: linear-gradient(180deg, var(--main-dark) 0%, rgba(0, 12, 31, 0.7) 41.67%, rgba(0, 12, 31, 0) 100%);
          border-radius: 7px;
          transform: matrix(0, -1, -1, 0, 0, 0);
        }
      }
    }
  }
}

@include bp($bp-phone) {
  .pre-footer {
    .row-btn {
      max-width: 100%;
    }
  }
}

.fa {
  .pre-footer {
    background: linear-gradient(180deg, #F1F7FE 0%, #F9FDFF 100%);
    padding-top: var(--spacing-140);

    &.landing-image {
      padding: 0;
      background-image: url('../assets/images/fa/fa-landing/fa-city-shadows.jpg');
      background-repeat: no-repeat;
      background-size: cover;
      height: 670px;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        height: 100%;
        width: 400px;
        background: linear-gradient(90deg, #F5FAFF 0%, rgba(245, 250, 255, 0.7) 41.67%, rgba(245, 250, 255, 0) 100%);
        opacity: 0.8;
      }

      &:after {
        content: '';
        position: absolute;
        right: 0;
        height: 100%;
        width: 400px;
        background: linear-gradient(-90deg, #F5FAFF 0%, rgba(245, 250, 255, 0.7) 41.67%, rgba(245, 250, 255, 0) 100%);
        opacity: 0.8;
      }

      .container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
      }
    }
  }
}
</style>
