<template>
  <div class="form-wrap" :class="{ flashcards: flashcards, 'flashcards-study': flashcardsStudy }">
    <div class="header" :class="{ 'back-action': backButton }">
      <div v-if="backButton" class="back-icon" @click="$emit('prev-step')">
        <IconArrow class="icon-arrow" />
      </div>
      <h2
        v-if="
          [
            '/',
            '/career-track-certificate/',
            '/course-certificate/',
            '/certificate/',
            '/upcoming-courses/',
            '/free-data-science-month/',
            '/start-your-journey-for-free/',
            '/start-your-journey-for-free-a/'
          ].includes($route.path)
        "
        class="h4"
      >
        {{ title }}
      </h2>
      <h1 v-else-if="!flashcards" class="h4">
        {{ title }}
      </h1>
      <h1 v-else-if="!flashcardsStudy" class="h5">
        Sign up to start a deck.
      </h1>
      <div v-else class="arrow-flashcards-study">
        <ArrowLeft v-if="hideSocials" @click="formSwapping" />
        <h1 class="h4" :class="{ signup: hideSocials }">
          Create Your Free Account
        </h1>
      </div>
    </div>
    <div v-if="!hideSocials" class="social-login-buttons">
      <div class="google-btn">
        <GoogleLoginButton
          :form-type="formType" :errored="termsError" :terms-agreed="signup.termsAgreed"
          :resources="resources"
          :data-event="`${eventPrefix}${tracking === 'signup' ? 'google.click' : 'sign_up_form_google.click'}`"
          data-async-event
          data-direct-send
          flashcards
          @check="checkTerms"
          v-on="$listeners"
        />
      </div>
      <div>
        <FacebookLoginButton
          :form-type="formType"
          :errored="termsError"
          :terms-agreed="signup.termsAgreed"
          :resources="resources"
          :keep-me-logged-in="!!keepMeLoggedIn"
          :data-event="`${eventPrefix}${tracking === 'signup' ? 'facebook.click' : 'sign_up_form_facebook.click'}`"
          data-async-event
          data-direct-send
          flashcards
          @check="checkTerms"
          v-on="$listeners"
        />
      </div>
      <div>
        <LinkedinLoginButton
          :form-type="formType"
          :errored="termsError"
          :terms-agreed="signup.termsAgreed"
          :resources="resources"
          :keep-me-logged-in="!!keepMeLoggedIn"
          :data-event="`${eventPrefix}${tracking === 'signup' ? 'linkedin.click' : 'sign_up_form_linkedin.click'}`"
          data-async-event
          data-direct-send
          @check="checkTerms"
          v-on="$listeners"
        />
      </div>
    </div>
    <div v-if="!hideSocials" class="divider" />
    <form
      v-if="showHideForm"
      class="signup-form"
      autocomplete="chrome-off"
      @submit.prevent="signupUser"
    >
      <div class="input-group" :class="{ invalid: nameAlert }">
        <input
          :id="`name${uniqueId}`" v-model="signup.name"
          type="text"
          name="name"
          autocomplete="name"
          placeholder="First and last name"
          :class="{ 'invalid-field': nameAlert }"
          :data-event="tracking === 'signup' ? `${eventPrefix}name.click` : ''"
          data-direct-send
          @input="nameAlert = ''"
        >
        <small v-if="nameAlert" class="alert">{{ nameAlert }}</small>
      </div>
      <div class="input-group input-email" :class="{ invalid: emailAlert, 'no-scrollbar-scroll overflowed': invitationEmail }">
        <template v-if="invitationEmail">
          <input
            type="hidden" autocomplete="username" name="username"
            :value="invitationEmail"
          >
          <span class="input-like disabled">
            {{ invitationEmail }}
          </span>
        </template>
        <input
          v-else
          :id="`signup-email${uniqueId}`"
          v-model="signup.email"
          type="text"
          class="signup-email"
          name="signup-email"
          autocomplete="username"
          placeholder="Email address"
          :class="{ 'invalid-field': emailAlert }"
          :data-event="tracking === 'signup' ? `${eventPrefix}email.click` : ''"
          data-direct-send
          @input="emailAlert = ''"
        >
        <small v-if="emailAlert" class="alert">{{ emailAlert }}</small>
      </div>
      <div class="input-group input-group-password" :class="{ invalid: passwordAlert || warningText }">
        <input
          :id="`password${uniqueId}`"
          v-model="signup.password"
          :type="visiblePassword ? 'text' : 'password'"
          name="password"
          autocomplete="current-password"
          placeholder="Password"
          :class="{ password: !visiblePassword, 'invalid-field': passwordAlert }"
          :data-event="tracking === 'signup' ? `${eventPrefix}password.click` : ''"
          data-direct-send
          @input="passwordAlert = ''"
          @keyup="validatePass"
        >
        <Component
          :is="visiblePassword ? 'IconEye' : 'IconEyeClosed'"
          class="icon-eye"
          @click="visiblePassword = !visiblePassword"
        />
        <small v-if="warningText" :class="warning" class="alert">{{ warningText }}</small>
      </div>
      <VueRecaptcha
        ref="recaptcha"
        class="recaptcha"
        size="invisible"
        :sitekey="$config.reCaptchaSiteKey"
        :load-recaptcha-script="true"
        @verify="signupUser"
        @expired="onRecaptchaExpired"
      >
        <button
          ref="signup_button"
          class="btn-lg submit h6"
          :class="buttonClass"
          type="submit"
          :disabled="disableButtons"
          :data-event="`${eventPrefix}${tracking === 'signup' ? 'signup_button.click' : 'sign_up_form_start_your_career.click'}`"
          :data-async-event="`${eventPrefix}${tracking === 'signup' ? 'signup_button.click' : 'sign_up_form_start_your_career.click'}`"
          data-direct-send
        >
          {{ buttonText }}
        </button>
      </VueRecaptcha>
      <div v-if="!hideSocials" class="input-group terms">
        <div :class="{ error: termsError }">
          <input
            :id="`termssignup${uniqueId}`" v-model="signup.termsAgreed" type="checkbox"
            class="custom-checkbox reverse-colors"
            :data-event="tracking === 'signup' ? `${eventPrefix}policy.click` : ''"
            data-direct-send
            @change="termsError = false"
          >
          <label :for="`termssignup${uniqueId}`">
            I agree with {{ $config.platformName }}’s
            <a target="_blank" :href="`${$config.siteUrl}/terms-of-use/`">Terms of Use</a>
            and
            <a target="_blank" :href="`${$config.siteUrl}/privacy-policy/`">Privacy Policy</a>
          </label>
        </div>
        <small v-if="termsError" class="alert alert-privacy">Please accept the Privacy Policy to sign up</small>
      </div>
      <p v-if="!flashcardsStudy && !invitationEmail" class="or-sign-up">
        Already have an account?
        <span v-if="resources" @click="$emit('login')">
          Log In
        </span>
        <nuxt-link v-else-if="!flashcards" to="/login/">
          Log In
        </nuxt-link>
        <a v-else @click="$emit('login')">
          Log In
        </a>
      </p>
    </form>
    <div v-else class="action-btn-terms">
      <div class="action-btn">
        <button @click="formSwapping('show')">
          <IconMail class="mail-icon" /> Continue with email
        </button>
      </div>
      <p class="terms">
        By continuing, you agree to {{ $config.platformName }}’s
        <a target="_blank" :href="`${$config.siteUrl}/terms-of-use/`">Terms of Use</a>
        and
        <a target="_blank" :href="`${$config.siteUrl}/privacy-policy/`">Privacy Policy</a>
      </p>
    </div>
  </div>
</template>

<script>
import IconArrow from '@itbpbg/lms-components/src/assets/svg/icon-arrow-back.svg?vue-component';
import IconMail from '@itbpbg/lms-components/src/assets/svg/icon-mail.svg?vue-component';
import VueRecaptcha from 'vue-recaptcha';
import ArrowLeft from '../assets/svg/flashcards-left-arrow.svg?vue-component';
import FacebookLoginButton from './FacebookLoginButton';
import GoogleLoginButton from './GoogleLoginButton';
import LinkedinLoginButton from './LinkedinLoginButton';
import IconEye from '../assets/svg/eye.svg?vue-component';
import IconEyeClosed from '../assets/svg/eye-closed.svg?vue-component';

export default {
  components: {
    VueRecaptcha,
    FacebookLoginButton,
    GoogleLoginButton,
    LinkedinLoginButton,
    IconEye,
    IconEyeClosed,
    IconArrow,
    ArrowLeft,
    IconMail,
  },
  props: {
    title: { type: String, default: 'Create Your Free Account' },
    buttonText: { type: String, default: 'Sign Up' },
    buttonClass: { type: String, default: 'btn-primary' },
    type: { type: String, default: '' },
    uniqueId: { type: String, default: '' },
    tracking: { type: String, default: '' },
    campaign: Boolean,
    hideSocials: Boolean,
    backButton: Boolean,
    resources: Boolean,
    flashcards: Boolean,
    flashcardsStudy: Boolean,
    eventPrefix: { type: String, default: '' },
    invitationEmail: { type: String, default: '' },
  },
  data() {
    return {
      confirmation: false,
      disableButtons: true,
      visiblePassword: false,
      signup: {
        email: '',
        name: '',
        password: '',
        termsAgreed: false,
      },
      warning: '',
      warningText: '',
      keepMeLoggedIn: false,
      termsError: false,
      formType: 'signup',
      nameAlert: '',
      emailAlert: '',
      passwordAlert: '',
      showHideForm: !this.flashcardsStudy,
    };
  },
  async created() {
    this.$root.$on('check-terms', () => {
      this.checkTerms();
    });
  },
  mounted() {
    if (this.$route.query.error && this.$route.query.error === 'business') {
      this.$store.commit('auth/setLoginAlert', true);
      const query = { ...this.$route.query };
      delete query.error;
      this.$router.replace({ query });
    }
    this.url = this.$route.params.url;
    this.disableButtons = false;
  },
  methods: {
    validatePass() {
      if (this.signup.password !== '') {
        // If the password length is less than or equal to 6
        if (this.signup.password.length < 6) {
          this.warning = 'short';
          this.warningText = 'Too short';
          this.passwordAlert = true;
        }
        // If the password length is greater than 6 and contain any lowercase alphabet or any number or any special character
        if (
          this.signup.password.length >= 6
          && (this.signup.password.match(/[a-z]/)
            || this.signup.password.match(/\d+/)
            || this.signup.password.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/))
        ) {
          this.warning = 'weak';
          this.warningText = 'Weak password';
          this.passwordAlert = false;
        }
        // If the password length is greater than 6 and contain alphabet,number,special character respectively
        if (
          this.signup.password.length >= 6
          && ((this.signup.password.match(/[a-z]/) && this.signup.password.match(/\d+/))
            || (this.signup.password.match(/\d+/)
              && this.signup.password.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/))
            || (this.signup.password.match(/[a-z]/)
              && this.signup.password.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/)))
        ) {
          this.warning = 'good';
          this.warningText = 'Good password';
          this.passwordAlert = false;
        }
        // If the password length is greater than 6 and must contain alphabets,numbers and special characters
        if (
          this.signup.password.length >= 6
          && this.signup.password.match(/[a-z]/)
          && this.signup.password.match(/\d+/)
          && this.signup.password.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/)
        ) {
          this.warning = 'strong';
          this.warningText = 'Strong password';
          this.passwordAlert = false;
        }
        // If the password length is greater than 100
        if (this.signup.password.length > 100) {
          this.warning = 'long';
          this.warningText = 'Too long';
          this.passwordAlert = true;
        }
      } else {
        this.warning = '';
        this.warningText = '';
        this.passwordAlert = true;
      }
    },
    checkTerms() {
      if (!this.signup.termsAgreed && !this.hideSocials) {
        this.termsError = true;

        return this.termsError;
      }
      this.termsError = false;
      return this.termsError;
    },
    async signupUser() {
      this.disableButtons = true;
      const { event } = this.$refs.signup_button.dataset;

      try {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!this.signup.name) {
          this.nameAlert = 'Required field';
          this.resetForm();
          this.$tracking.sendAsyncEvent(event, { status: 'fail', error: 'Please fill in name!' });
          return;
        }
        if ((!this.signup.email || !re.test(this.signup.email)) && !this.invitationEmail) {
          this.emailAlert = '*Invalid Email';
          this.resetForm();
          this.$tracking.sendAsyncEvent(event, { status: 'fail', error: 'Please fill in valid email!' });
          return;
        }
        this.validatePass();
        if (this.passwordAlert) {
          if (this.warning === 'long') {
            this.warningText = 'Maximum password length is 100 symbols';
            this.$tracking.sendAsyncEvent(event, { status: 'fail', error: 'Maximum password length is 100 symbols' });
          } else {
            this.warning = 'short';
            this.warningText = 'Minimum password length is 6 symbols ';
            this.$tracking.sendAsyncEvent(event, { status: 'fail', error: 'Minimum password length is 6 symbols' });
          }
          this.resetForm();
          return;
        }

        if (!this.signup.termsAgreed && !this.hideSocials) {
          this.termsError = true;
          this.resetForm();
          return;
        }
        let signup;
        if (this.type === 'gateway') {
          signup = await this.$axios.$post('/auth/beta/sign-up', {
            email: this.signup.email,
            name: this.signup.name,
          });
        } else if (this.$route.query.key && this.invitationEmail) {
          signup = await this.$axios.$post('/instructor/sign-up', {
            email: this.invitationEmail,
            name: this.signup.name,
            password: this.signup.password,
            key: this.$route.query.key,
          }, { noError: true }).catch((e) => e.response.data);
        } else {
          signup = await this.$store.dispatch('auth/register', {
            email: this.signup.email,
            name: this.signup.name,
            password: this.signup.password,
            campaign: this.campaign,
            resources: this.resources,
            course: this.$route.query.course,
          });
        }
        if (signup) {
          if (signup.status !== 'error') {
            const eventID = `${this.$store.state.tracking.sessionId}${Date.now()}`;
            dataLayer.push({ event: 'FreeSignup', signupType: 'Direct', eventID });
            this.$tracking.sendEvent({
              dataLayerEvent: 'SignUp',
              destinationUrl: this.$router.currentRoute.fullPath,
              eventID,
              capi: true,
              userAgent: navigator.userAgent,
            });

            this.$root.$emit('login');

            await this.$tracking.sendAsyncEvent(event, { status: 'success' });

            if (this.resources) {
              this.$emit('continueToExamResult');
              return;
            }

            if (this.invitationEmail) {
              this.$redirectAfterLogin('/instructor/courses/');
            }

            if (this.$route.query.course) {
              this.$redirectAfterLogin('/');
            } else if (!this.flashcards && !this.invitationEmail) {
              this.$redirectAfterLogin('/my-learning-path/');
            }

            return;
          }

          if (signup && signup.status === 'error' && signup.error === 'userAlreadyExists') {
            this.$store.dispatch('modals/open', {
              name: 'default',
              title: 'Signup error',
              message: signup.message,
              confirm: 'Done',
              cancel: '',
              noBtnClose: true,
            });
          }

          this.$tracking.sendAsyncEvent(event, { status: 'fail', error: signup.message });
        }
        this.resetForm();
      } catch (error) {
        this.resetForm();
      }
      this.resetForm();
    },
    onRecaptchaExpired() {
      this.resetForm();
    },
    resetForm() {
      this.disableButtons = false;
      this.$refs.recaptcha.reset();
    },
    formSwapping(showHide) {
      if (showHide === 'show') {
        this.showHideForm = true;
      } else {
        this.showHideForm = false;
      }

      this.$emit('email-choice');
    },
  },
};
</script>

<style lang="scss" scoped>
.form-wrap {
  background-color: var(--main-light);
  border-radius: 10px;
  padding: var(--spacing-48);
  max-width: 446px;
  width: 100%;

  .header {
    &.back-action {
      position: relative;
      padding-left: var(--spacing-8);

      .back-icon {
        position: absolute;
        left: -6px;
        top: 50%;
        padding: var(--spacing-6);
        transform: translateY(-50%);
        cursor: pointer;
      }
    }
  }

  h1,
  h2 {
    margin-top: 0;
    text-align: center;
    margin-bottom: var(--spacing-32);
  }

  h1 {
    margin-bottom: var(--spacing-24);
  }

  .divider {
    position: relative;
    padding: var(--spacing-36) 0;

    &:before,
    &:after {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &:before {
      background-color: var(--main-light);
      color: var(--main-dark-opacity-48);
      content: 'or';
      font-size: 16px;
      padding: var(--spacing-12);
      z-index: 2;
    }

    &:after {
      border-top: 1px solid var(--main-dark-opacity-18);
      content: '';
      width: 100%;
    }
  }

  .input-group {
    position: relative;
    padding-bottom: 12px;

    &.input-email .alert {
      position: unset;
      line-height: 110%;
      font-size: 12px;
    }

    &.input-group-password {
      padding-bottom: 0;
      margin-bottom: var(--spacing-20);
    }

    &:last-of-type .alert {
      padding-left: var(--spacing-26);
    }

    &.links {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
    }

    input.invalid-field {
      border-color: var(--main-red);
    }

    .alert {
      position: absolute;
      top: 100%;
      padding-left: 0;
      color: var(--main-red);
      line-height: 24px;

      &.alert-privacy {
        position: static;
        text-align: center;
        display: block;
        margin-top: var(--spacing-8);
        line-height: 130%;
        padding: var(--spacing-12);
        background-color: var(--main-red-light-2);
        border: 1px solid var(--main-red-light-3);
        border-radius: 5px;
        color: var(--main-red-shade-1);
      }
    }

    .icon-eye {
      cursor: pointer;
      position: absolute;
      right: var(--spacing-16);
      top: calc(50% - 12px);
    }

    small {
      color: var(--main-red);
      padding-left: 26px;
    }
  }

  small {
    &.short,
    &.weak,
    &.error {
      color: var(--main-red);
    }

    &.good {
      color: var(--accent) !important;
    }

    &.strong {
      color: var(--main-green-shade-1) !important;
    }
  }

  .submit {
    font-weight: 600;
    margin: var(--spacing-12) auto var(--spacing-16);
    width: 100%;
  }

  .terms {
    padding-bottom: 18px;
  }

  .or-sign-up {
    color: var(--main-dark-opacity-48);
    text-align: center;
    margin: 18px 0 0;
    font-size: 16px;
    line-height: 150%;

    span {
      color: var(--link-color);
      cursor: pointer;
    }
  }

  @include bp($bp-mobile) {
    padding: var(--spacing-24);

    h1 {
      margin-bottom: var(--spacing-36);
    }

    &.upcoming {
      .divider:before {
        background-color: var(--main-light-shade-1);
      }
    }
  }
}

.social-login-buttons {
  display: block;
  overflow: hidden;

  > div {
    border-radius: 5px;
    border: 1px solid var(--main-dark-opacity-12);
    float: left;
    height: 52px;
    width: 52px;

    &:hover {
      background-color: var(--main-light-shade-1);
      border: 1px solid var(--main-dark-opacity-12);
    }

    &:not(:last-of-type) {
      margin-right: 12px;
    }

    &.google-btn {
      width: calc(100% - 128px);
    }
  }
}

.fa {
  @include bp($bp-mobile) {
    .form-wrap {
      &.upcoming {
        .divider:before {
          background-color: var(--main-light);
        }
      }
    }
  }
}

.flashcards {
  padding: 0;
  margin: var(--spacing-50) auto var(--spacing-30) auto;
  background-color: unset;

  &.flashcards-study {
    width: 332px;
    margin: 0;

    .signup-form {
      padding: 0;

      .submit {
        height: 56px;
        margin: 0;
      }
    }

    .arrow-flashcards-study {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: var(--spacing-16);

      h1 {
        margin: 0;
        white-space: nowrap;

        &.signup {
          margin: 0 0 0 var(--spacing-20);
        }
      }

      svg {
        width: 8px;
        height: 12px;
        fill: var(--main-dark-light-4);
        cursor: pointer;
      }
    }

    .social-login-buttons {
      padding: 0;
    }

    .divider {
      padding: 21px 0;

      &:before {
        padding: var(--spacing-8) var(--spacing-12);
        background-color: var(--main-light);
      }
    }

    .action-btn-terms {
      .action-btn {
        border: 1px solid rgba(0, 12, 31, 0.18);
        border-radius: 5px;

        .mail-icon {
          height: 24px;
        }

        &:not(:last-child) {
          margin-bottom: var(--spacing-12);
        }

        &:hover {
          background-color: var(--main-light-shade-1);
          border: 1px solid var(--main-dark-opacity-12);
        }

        > button {
          border: none;
          background: none;
          padding: 0;
          margin: 0;
          width: 100%;
          font-size: 100%;
          font-family: inherit;
          line-height: normal;
          cursor: pointer;

          > svg {
            width: 24px;
            padding-left: 2px;
            padding-right: 2px;

            > path[stroke] {
              stroke: var(--main-dark-light-3);
            }
          }
        }

        > a,
        a.visible,
        button {
          display: flex;
          align-items: center;
          padding: var(--spacing-14) var(--spacing-44);
          line-height: unset;
          font-size: 18px;

          > svg {
            margin-right: 15px;
            width: 24px;
          }
        }

        .invisible {
          overflow: hidden;
        }
      }

      .terms {
        display: block;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        margin: var(--spacing-24) 0 0;
        color: rgba(0, 12, 31, 0.48);
        line-height: 130%;
      }
    }
  }

  .signup-form,
  .social-login-buttons {
    padding: 0 66px;
  }

  .divider {
    width: 314px;
    margin: 0 auto;

    &:before {
      background-color: var(--main-light-shade-1);
    }
  }
}

.start-your-journey-for-free-v2 {
  max-width: 470px;
  box-shadow: 0 0 60px 0 var(--extreme-blue);

  .signup-form {
    .recaptcha {
      ::v-deep .btn-primary {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 58px;
        margin: 0 auto;
        padding: 20px 0 18px 0;
        color: var(--main-dark);
        background-color: var(--extreme-yellow);
        border: 2px solid var(--extreme-yellow);
        border-radius: 5px;
        font-size: 18px;
        font-weight: 600;
        line-height: 18px;

        &.submit {
          transition: all 50ms linear;
          border-color: var(--main-dark);
          background-color: var(--extreme-yellow);
          margin-top: 20px;
          margin-bottom: 12px;
          line-height: 30px;
        }

        &:not(:disabled) {
          &:hover,
          &:active,
          &:focus {
            color: var(--main-dark);
            background-color: var(--extreme-yellow);

            &:not(.submit) {
              filter: drop-shadow(0 0 30px var(--extreme-yellow));
              border-color: var(--extreme-yellow);
            }

            &.submit {
              background-color: var(--extreme-yellow);
              border-color: var(--main-dark);
              outline: 1px solid var(--main-dark) !important;
            }
          }
        }
      }
    }
  }
}

@include bp($bp-laptop) {
  .flashcards {
    &.flashcards-study {
      width: 310px;

      .arrow-flashcards-study {
        margin-bottom: var(--spacing-20);

        h1 {
          font-size: 21px;
        }
      }
    }
  }
}

@include bp($bp-mobile) {
  .flashcards {
    margin-top: var(--spacing-32);
    margin-bottom: 0;

    &.flashcards-study {
      .header {
        padding: 0;

        .arrow-flashcards-study {
          margin-bottom: var(--spacing-22);

          .h4 {
            font-size: 21px;
          }

          .signup {
            margin-left: 59px;
          }
        }
      }

      .divider {
        width: 100%;
        padding: 28px 0;
      }

      .action-btn-terms {
        .action-btn {
          margin-bottom: 0;

          button {
            padding: var(--spacing-14) var(--spacing-36);
          }
        }

        .terms {
          display: none;
        }
      }
    }

    h1 {
      margin-bottom: var(--spacing-16);
    }

    .signup-form,
    .social-login-buttons {
      padding: 0 var(--spacing-32);
    }
  }
}

@include bp($bp-phone) {
  .flashcards {
    .header {
      padding: 0 var(--spacing-32);
    }

    .divider {
      width: 287px;
    }

    &.flashcards-study {
      width: 100%;

      .action-btn-terms {
        .action-btn.action-btn {
          margin: 0;

          button {
            padding: var(--spacing-14) 0;
            justify-content: center;
          }
        }
      }

      .arrow-flashcards-study {
        .signup.signup {
          margin-left: var(--spacing-14);
        }
      }
    }
  }
}
</style>
